import React from "react"
import { shape } from "prop-types"
import Layout, { layoutProps } from "../components/Layout"
import Row from "../components/Layout/Content/Row"
import HTMLContent from "../components/HTMLContent"

const Simple = ({ pageContext }) => {
	return (
		<Layout {...pageContext}>
			<Row>
				<HTMLContent innerHTML={pageContext.html} />
			</Row>
		</Layout>
	)
}

Simple.propTypes = {
	pageContext: shape({
		...layoutProps,
	}).isRequired,
}

export default Simple
